<template>
  <div class="d-flex">
    <CButton
      color="primary"
      variant="outline"
      title="Modifica"
      size="sm"
      class="mr-2"
      @click.prevent="onInvoiceEdit"
    >
      {{ $t("role.finance.invoicesOut.tableActions.modify") }}
    </CButton>
    <CButton
      color="primary"
      size="sm"
      title="Emetti"
      @click.prevent="onInvoiceEmit"
      >{{ $t("role.finance.invoicesOut.tableActions.sent") }}</CButton
    >
  </div>
</template>

<script>
export default {
  name: "InvoicesOutTableActions",
  methods: {
    onInvoiceEdit() {
      const invoice = this.data;
      this.$store.commit("invoices/openEditModal", {
        invoice,
      });
    },

    onInvoiceEmit() {
      const invoice = this.data;
      this.$store.commit("invoices/openEmitModal", {
        invoice,
      });
    },
  },
};
</script>
